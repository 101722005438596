<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="50%"
    class="adaptationModal"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        :label-width="$l('yearCheck.labelWidth','100px')"
        :model="yearCheck"
        :rules="ruleValidate"
        style="padding-right: 20px">
        <el-form-item v-if="yearCheck.id > 0" :label="$t('yearCheck.yearCheckNo')">
          {{yearCheck.yearCheckNo}}
        </el-form-item>
        <el-form-item :label="$t('elevator.no')" prop="elevatorNo">
          <el-input v-model="yearCheck.elevatorId" style="display: none"></el-input>
          <el-input :placeholder="$t('common.pleaseSelect')" :value="yearCheck.elevatorNo" readonly>
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="$refs.selectElevator.open('inNetState:' + inNetState)">
            </el-button>
          </el-input>
          <elevator-select ref="selectElevator" @select="onSelectElevator"></elevator-select>
        </el-form-item>
        <el-form-item :label="$t('elevator.useUnit')">
          <el-input :value="yearCheck.useUnitName" readonly></el-input>
        </el-form-item>
        <el-form-item :label="$t('elevator.maintenanced')">
          <el-input :value="yearCheck.maintenanceComName" readonly></el-input>
        </el-form-item>
        <el-form-item :label="$t('yearCheck.checkDate')" prop="checkDate" :rules="$rule.notNull">
          <el-date-picker
            v-model="yearCheck.checkDate"
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="$t('yearCheck.date')">
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('yearCheck.status')" prop="status">
          <el-radio-group v-model="yearCheck.status">
            <el-radio class="radio" :label="0">{{$t("yearCheck.result.noPass")}}</el-radio>
            <el-radio class="radio" :label="1">{{$t("yearCheck.result.pass")}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('yearCheck.note')" prop="note">
          <el-input v-model="yearCheck.note" :placeholder="$t('common.pleaseEnter')" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="年检文件">
          <el-upload
            :action="uploadUrl"
            :headers="uploadHeaders"
            :before-upload="beforeUpload"
            :on-error="handlerError"
            :on-success="handleSuccess"
            :data="data"
            :multiple="true"
            :show-file-list="false"
            accept=".png, .jpeg, .jpg, .doc, .pdf, .docx, .xlsx">
            <el-button
              :loading="uploadLoading"
              icon="el-icon-upload2"
              size="mini"
              type="primary">
              {{$l("programCard.upload", "上传")}}
            </el-button>
          </el-upload>
          <div v-if="yearCheck.id>0" style="display:inline-block;margin-top: 8px">
            <el-tag
              v-for="item in yearCheck.fileList"
              :key="item.id"
              size="medium"
              style="margin-right: 10px"
              closable
              @close="handleTagClose(item.id)">
              <a style="text-decoration:none;" href="#" @click="downloadFile(item.path,item.name)">{{item.name}}</a>
            </el-tag>
          </div>
          <div v-else style="display:inline-block;margin-top: 8px">
            <el-tag
              v-for="item in files"
              :key="item.id"
              size="medium"
              style="margin-right: 10px"
              closable
              @close="handleTagClose(item.id)">
              <a style="text-decoration:none;" href="#" @click="downloadFile(item.path,item.name)">{{item.name}}</a>
            </el-tag>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import ElevatorSelect from "../elevator/ElevatorSelect.vue";
  import auth from "@/util/auth";

  const moduleName = "yearChecks";

  export default {
    components: {ElevatorSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        inNetState: 1,
        uploadLoading: false,
        uploadUrl: window.config.SERVER_URL + "files/upload/yearCheck",
        uploadHeaders: {Authorization: auth.getToken()},
        yearCheck: {
          id: 0,
          elevatorId: 0,
          quantityOrganId: 0,
          useUnitId: 0,
          maintenanceComId: 0,
          yearCheckNo: "",
          elevatorNo: "",
          useUnitName: "",
          quantityOrganName: "",
          maintenanceComName: "",
          checkDate: "",
          status: 0,
          note: "",
          fileList: [],
          fileIds: [],
        },
        files: [],
        data: {
          module: "yearCheck",
          relatedId: 0,
          relatedType: "1",
          fileName: "",
          isImage: false,
        },
        ruleValidate: {
          elevatorNo: [
            {required: true, message: this.$t("yearCheck.tip"), trigger: "change"},
          ],
        },
      };
    },
    computed: {
      title() {
        return (this.yearCheck.id > 0 ? this.$t("common.edit") : this.$t("common.add")) + " " + this.$t("route.annualInspection");
      },
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.yearCheck.id = id;
        this.data.relatedId = id;
        this.yearCheck.fileIds = [];
        this.files = [];
        if (id > 0) {
          this.getData();
        }
      },
      check(id) {
        this.dialogVisible = true;
        this.yearCheck.id = id;
        this.data.relatedId = id;
        if (id > 0) {
          this.contentLoading = true;
          this.saveDisabled = true;
          this.$api.getById(moduleName, this.yearCheck.id).then(res => {
            this.yearCheck = res.data;
            this.yearCheck.id = 0;
            this.yearCheck.checkDate = "";
            this.contentLoading = false;
            this.saveDisabled = false;
          }).catch((error) => {
            this.contentLoading = false;
            if (error.response) {
              this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
            }
          });
        }
      },
      checkRow(row) {
        this.dialogVisible = true;
        this.yearCheck = row;
        this.yearCheck.id = 0;
        this.yearCheck.checkDate = "";
        this.contentLoading = false;
        this.saveDisabled = false;
      },

      getData() {
        this.contentLoading = true;
        this.saveDisabled = true;
        this.$api.getById(moduleName, this.yearCheck.id).then(res => {
          this.yearCheck = res.data;
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      downloadFile(url, name) {
        fetch(url).then(response => response.blob()).then(result => {
          let objectUrl = window.URL.createObjectURL(result);
          let link = document.createElement("a");
          link.href = objectUrl;
          link.setAttribute("download", name);
          link.click();
          link.remove();
        });
      },
      beforeUpload(file) {
        let fileName = file.name;
        let pos = fileName.lastIndexOf(".");
        let lastName = fileName.substring(pos, fileName.length);
        let size = file.size / 1024 / 1024;
        if (lastName.toLowerCase() === ".png" ||
          lastName.toLowerCase() === ".jpeg" ||
          lastName.toLowerCase() === ".jpg") {
          if (size > 3) {
            this.$message.error(this.$l("programCard.imageFiles", "图片文件不能超过 3MB!"));
            return false; // 返回false中止上传
          } else {
            this.data.fileName = fileName;
            this.data.isImage = true;
          }
        }
        if (lastName.toLowerCase() === ".doc" ||
          lastName.toLowerCase() === ".docx" ||
          lastName.toLowerCase() === ".pdf" ||
          lastName.toLowerCase() === ".xlsx") {
          if (size > 5) {
            this.$message.error(this.$l("programCard.videoFiles", "文档文件不能超过 5MB!"));
            return false; // 返回false中止上传
          } else {
            this.data.fileName = fileName;
            this.data.isImage = false;
          }
        }
        if (lastName.toLowerCase() !== ".png" &&
          lastName.toLowerCase() !== ".jpeg" &&
          lastName.toLowerCase() !== ".jpg" &&
          lastName.toLowerCase() === ".doc" &&
          lastName.toLowerCase() === ".docx" &&
          lastName.toLowerCase() === ".pdf" &&
          lastName.toLowerCase() === ".xlsx"
        ) {
          this.$message.error(this.$l("programCard.fileType", "请上传允许的文件类型"));
          return false; // 返回false中止上传
        }
      },
      handleSuccess(res) {
        this.uploadLoading = false;
        if (this.yearCheck.id > 0) {
          this.getData();
        } else {
          this.$api.getById("files", res).then(res => {
            this.files.push(res.data);
            this.yearCheck.fileIds.push(res.data.id);
          });
        }
        this.$message.success(this.$l("programCard.uploadSuccessful", "上传成功"));
      },
      handlerError(err) {
        this.$message.error("上传失败");
        this.uploadLoading = false;
      },
      handleTagClose(id) {
        this.$http.delete("files", id).then(() => {
          if (this.yearCheck.id > 0) {
            this.getData();
          } else {
            this.files.map(item => {
              if (item.id === id) {
                let index = this.files.indexOf(item);
                this.files.splice(index, 1);
              }
            });
            this.fileIds.forEach(fileId => {
              if (fileId === id) {
                let index = this.fileIds.indexOf(fileId);
                this.fileIds.splice(index, 1);
              }
            });
          }
        });
      },
      onCancel() {
        this.dialogVisible = false;
        if (this.yearCheck.fileIds) {
          this.yearCheck.fileIds.forEach(id => {
            this.$http.delete("files", id).then(() => {

            });
          });
        }
      },
      onDialogClose() {
        this.saveDisabled = false;
        this.contentLoading = false;
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
        this.$refs.formValidate.resetFields();
      },
      onSelectElevator(row) {
        this.yearCheck.elevatorId = row.id;
        this.yearCheck.useUnitId = row.useUnitId;
        this.yearCheck.quantityOrganId = row.quantityOrganId;
        this.yearCheck.maintenanceComId = row.maintComId;
        this.yearCheck.elevatorNo = row.no;
        this.yearCheck.useUnitName = row.useUnitName;
        this.yearCheck.quantityOrganName = row.quantityOrganName;
        this.yearCheck.maintenanceComName = row.maintComName;
      },
      onSelectQuantityOrgan(row) {
        this.yearCheck.quantityOrganId = row.id;
        this.yearCheck.quantityOrganName = row.name;
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.$api.save(moduleName, this.yearCheck).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", this.yearCheck.id === 0);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
